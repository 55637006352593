import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ThemeProvider from './theme/ThemeProvider';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AuthProvider} from 'react-oidc-context';
import {Log} from 'oidc-client-ts';

import 'index.css';
import App from "App";
import {store} from 'app/store';
import reportWebVitals from 'reportWebVitals';

const container = document.getElementById('root')!;
const root = createRoot(container);

Log.setLogger(console);

const oidcConfig = {
    authority: process.env.REACT_APP_OIDC_AUTHORITY as string,
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID as string,
    redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI as string,
};

root.render(
    <React.StrictMode>
        <AuthProvider {...oidcConfig}>
            <Provider store={store}>
                <ThemeProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <BrowserRouter>
                            <App/>
                        </BrowserRouter>
                    </LocalizationProvider>
                </ThemeProvider>
            </Provider>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
